<template>
  <v-app>
    <v-app-bar fixed app flat>
      <v-toolbar-title class="primary--text"> E4F </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-main>
      <v-container>
        <slot />
      </v-container>
    </v-main>
    <v-footer class="d-flex justify-center" absolute app>
      <div class="pt-10 d-flex">
        <span class="mr-10">&copy; {{ new Date().getFullYear() }}</span>
        <NuxtLink style="text-decoration: none">Imprint</NuxtLink>
        <div class="mx-3" style="border-left: 1px solid gray"></div>
        <NuxtLink style="text-decoration: none">Privacy Policy</NuxtLink>
      </div>
    </v-footer>
  </v-app>
</template>
